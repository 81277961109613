<template>
  <div class="mt-1 sub-title">This World is included in:</div>
  <div class="included-location mt-3">
    <div v-for="(item, index) of included_locations" :key="index">
      <router-link :to="{ name: 'world-details', params: { slug: item.slug } }" class="text-black">
        <div class="one-included-location d-flex align-items-center clickable-item">
          <div class="value">
            <span>{{ item.name }}</span>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  value: {
    type: Array,
    default: [],
  },
});

const included_locations = toRef(props, 'value');
</script>

<style lang="sass" scoped>
.sub-title
  font-size: 18px
  font-weight: bold
.dark .sub-title
  color: white
.one-included-location
  min-height: 30px
  margin-bottom: 10px
  background: #FFF
  border-radius: 10px
  color: #45B6F5
  padding: 0 24px
  .value
    font-weight: bold
  a
    color: #45B6F5
.dark
  .one-included-location, .value
    color: white !important
.dark .one-included-location
  background: #11022a !important
</style>
