export const possibleSectionsLabelMap: { [key: string]: string } = {
  description: 'Description',
  visual_collections_page: 'Images',
  subworlds: 'Locations',
  character_worlds: 'Characters',
  featured_collections: 'Folders',
};
export const possibleExtraSectionsLabelMap: { [key: string]: string } = {
  about: 'About',
  colors: 'Color Palette',
  featuredin: 'Featured in',
  spotify_link: 'Theme Song',
};
